import React from 'react';
import MapContainer from './MapContainer'
import '@brainhubeu/react-carousel/lib/style.css'

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      tabs: ['Modelos', 'Boyas', 'Estaciones'],
      selectedIndex: 0
    }
  }

  render() {
    return (
          <div className="Home">
            <MapContainer />
          </div>
    );
  }
  
}

export default Home;

/*


                

                

                
                
*/