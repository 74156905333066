import React from 'react'
import Home from './Home'
import './AppContainer.css'

class AppContainer extends React.Component {

  render() {
    return (
      <div className="AppContainer">
        <Home />
      </div>
    );
  }
  
}

export default AppContainer;