import React from 'react';
import { Map, Marker, TileLayer, Polygon, Circle, Popup } from 'react-leaflet'
import axios from 'axios'

export default function MapContainer() {
  const [data, setData] = React.useState(false)

  React.useEffect(() => {
    setInterval(() => {
      getData()
    }, 1000)
  }, [])

  const getData = async() => {
    try{
      const response = await axios.get('https://217.61.113.253:8501/status')
      console.log(response.data)
      setData(response.data)
    } catch(e) {
      console.log(e)
    }
  }

  const position = [19.5332, -96.9227]

  return (
    <div className="MapContainer" style={{ height: '100vh' }}>
      <Map center={[19.5345, -96.8967]} zoom={14} scrollWheelZoom={false}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"//"https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Circle center={ position } fillColor='black' color={ data ? 'white' : 'black' } radius={100} />
      </Map>
    </div>
  )
}